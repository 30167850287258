import { SVGProps } from 'react';

interface iSectionHeaderProps {
  content: {
    title: string;
    subtitle: string;
    buttonTitle: string;
    buttonIcon: any;
  };
}

function SectionHeader({ content }: iSectionHeaderProps) {
  return (
    <div className="flex justify-center">
      <div className="mt-[30px] sm:mt-[80px] flex max-w-[954px] flex-col items-center justify-center">
        <div className="flex  gap-[4px] rounded-[99px] bg-[#F2F2F2] px-4 py-3 font-semibold text-[#444444]">
          <content.buttonIcon className="w-[16px]" />
          <span>{content.buttonTitle}</span>
        </div>

        <span className="mt-[32px] mb-[24px] text-center text-[#7D7D7D] sm:text-[18px] text-[16px] font-medium ">
          {content.subtitle}
        </span>

        <span className="text-center leading-7 sm:leading-none text-[25px] font-extrabold  text-[#191919] sm:text-[50px]">
          {content.title}
        </span>
      </div>
    </div>
  );
}

export default SectionHeader;
