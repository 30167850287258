import React from 'react';
import Head from 'next/head';

interface IMetaDefaultProps {
  title?: string;
  description?: string;
  img?: string;
}

const MetaDefault = ({ title, description, img }: IMetaDefaultProps) => {
  const meta = {
    title: title ? `${title} - MisFans` : 'MisFans',
    description: description || 'La única plataforma que necesitas para monetizar tu audiencia.',
    img: img || '/og.png'
  };

  return (
    <Head>
      <title>{meta.title}</title>

      <meta name="robots" content="follow, index" />

      <meta content={meta.description} name="description" />

      <meta property="og:url" content={process.env.NEXT_PUBLIC_URL} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:image" content={meta.img} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={process.env.NEXT_PUBLIC_URL} />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      <meta name="twitter:image" content={meta.img} />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicons/apple-touch-icon.png"
      />

      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicons/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicons/favicon-16x16.png"
      />

      <link rel="shortcut icon" href="/favicon.ico" />

      <link rel="manifest" href="/manifest.json" />

      <script
        async
        defer
        src="https://tools.luckyorange.com/core/lo.js?site-id=85026437"
      ></script>
    </Head>
  );
};

export default MetaDefault;
