import { useState, useEffect } from 'react';
import LandingHero from 'components/landingComponents/hero';
import InfiniteCarousel from 'components/landingComponents/infiniteCarouselProfiles/infiniteCarousel';
import LandingNavbar from 'components/landingComponents/navbar';
import Highlights from 'components/landingComponents/highlights';
import CTA from 'components/landingComponents/cta';
import SectionHeader from 'components/landingComponents/sectionHeader';
import HerramientasGallery from 'components/landingComponents/herramientasGallery';
import Integrations from 'components/landingComponents/integrations';
import Footer from 'components/tailwind/footer';
import ImageSection from 'components/landingComponents/imageSection';
import Brands from 'components/landingComponents/brands';
import MoneyFlow from 'components/landingComponents/moneyFlow';
import PaymentMethods from 'components/landingComponents/paymentMethods';
import ChartFa from 'components/tailwind/icons/ChartFa';
import ToolboxFa from 'components/tailwind/icons/ToolboxFa';
import LockFa from 'components/tailwind/icons/LockFa';
import HeartboxFa from 'components/tailwind/icons/HeartboxFa';
import HandFa from 'components/tailwind/icons/HandFa';
import QuestionBlockFa from 'components/tailwind/icons/QuestionBlockFa';
import AudiosFa from 'components/tailwind/icons/AudiosFa';
import DownloadFa from 'components/tailwind/icons/DownloadFa';
import MediaKitFa from 'components/tailwind/icons/MediaKitFa';
import LinkinbioFa from 'components/tailwind/icons/LinkinbioFa';
import PagosFa from 'components/tailwind/icons/PagosFa';
import BillFa from 'components/tailwind/icons/BillFa';
import ExchangeFa from 'components/tailwind/icons/ExchangeFa';
import BankFa from 'components/tailwind/icons/BankFa';

import Scroll from 'react-scroll';
import BasicButton from 'components/tailwind/BasicButton';
import InverseInfiniteCarousel from 'components/landingComponents/inverseInfiniteCarousel/infiniteCarousel';
import MarketFa from 'components/tailwind/icons/MarketFa';
import ArrowUpRightFa from 'components/tailwind/icons/ArrowUpRightFa';
import InfiniteCarouselMobile from 'components/landingComponents/infiniteMobile/infiniteCarouselProfiles/infiniteCarousel';
import BasicButtonLanding from 'components/tailwind/BasicButtonLanding';
import InfiniteCarouselInverseMobile from 'components/landingComponents/InfiniteCarouselInverese/infiniteCarousel';
import MetaDefault from 'components/meta/MetaDefault';

// Tarjetas de usuarios para el carrusel
const profilesCards = [
  {
    username: 'tefivalenzuela',
    image:
      'https://firebasestorage.googleapis.com/v0/b/misfans-5dfa8.appspot.com/o/profiles%2F2kBQKCFbhNxeW4bikRO8%2Fprofile_image?alt=media&token=e697a1d8-627d-4cc6-9e8c-35fc6486fbd1'
  },
  {
    username: 'kristiemartin',
    image:
      'https://ik.imagekit.io/txlzhrdtltb/17efbc64-60ac-44c6-a342-598fa39b24de_mfxLENk9l.jpg'
  },
  {
    username: 'wasabichi',
    image:
      'https://firebasestorage.googleapis.com/v0/b/misfans-5dfa8.appspot.com/o/profiles%2FBOstqpoJxAw0u159qBe4%2Fprofile_image?alt=media&token=b4665a12-d811-4712-afe1-998545bba042'
  },
  {
    username: 'maurygames',
    image:
      'https://firebasestorage.googleapis.com/v0/b/misfans-5dfa8.appspot.com/o/profiles%2FGrA5PIk30VDZbIwBKE20%2Fprofile_image?alt=media&token=f9ed057f-d036-4a03-9002-d0143499269f'
  },
  {
    username: 'unarabeenmexico',
    image:
      'https://firebasestorage.googleapis.com/v0/b/misfans-5dfa8.appspot.com/o/profiles%2FOSSMgOqa4kd7LdhTrU51%2Fprofile_image?alt=media&token=16173aa7-8820-4193-9193-5b564525e4e6'
  },
  {
    username: 'aldarayalex',
    image:
      'https://firebasestorage.googleapis.com/v0/b/misfans-5dfa8.appspot.com/o/profiles%2FxwvbqPI5mm8H20fwzgfX%2Fprofile_image?alt=media&token=0cd13c17-fbca-4c84-9995-065d7c4dd712'
  },
  {
    username: 'elabnormal',
    image:
      'https://ik.imagekit.io/txlzhrdtltb/1d400d39-1c98-46bc-bb82-e737b49b3787_U0ihFmOS1.jpg'
  },
  {
    username: 'pauusaurio',
    image:
      'https://ik.imagekit.io/txlzhrdtltb/cd47b14b-6543-4c3a-9ff3-d218a9972533_-tsHn3ZkV.jpg'
  },
  {
    username: 'bryangeovany',
    image:
      'https://firebasestorage.googleapis.com/v0/b/misfans-5dfa8.appspot.com/o/profiles%2FFMCaISOKXBMtO9jINBDs%2Fprofile_image?alt=media&token=90e182b2-72c1-407c-a19e-b19fca255a1e'
  },
  {
    username: 'issarho',
    image:
      'https://ik.imagekit.io/txlzhrdtltb/8cef8cd3-5e6a-4a5f-bacb-ee4d4a2d24cc_eTxrgclD5.jpg'
  },
  {
    username: 'alepshua',
    image:
      'https://firebasestorage.googleapis.com/v0/b/misfans-5dfa8.appspot.com/o/profiles%2FdHBOPxAkGDYxBftcFGHe%2Fprofile_image?alt=media&token=9e0ba425-52fe-4532-afa9-8c9e4408acc0'
  },
  {
    username: 'lalobri',
    image:
      'https://firebasestorage.googleapis.com/v0/b/misfans-5dfa8.appspot.com/o/profiles%2Fuxjxz3I2vucdk7vscTFe%2Fprofile_image?alt=media&token=bfc3c4da-91c1-49f3-a55a-636848e5eba8'
  },
  {
    username: 'gndx',
    image:
      'https://firebasestorage.googleapis.com/v0/b/misfans-5dfa8.appspot.com/o/profiles%2FeBOaySBJcY4Jcc8lfOYn%2Fprofile_image?alt=media&token=e0d94777-7ee5-4c76-ad51-1e00273406c5'
  },
  {
    username: 'xnt',
    image:
      'https://firebasestorage.googleapis.com/v0/b/misfans-5dfa8.appspot.com/o/profiles%2Fv8bEXtdo1B4hyWNar7jC%2Fprofile_image?alt=media&token=37c8dcff-4911-459c-ac41-536888f5995e'
  },
  {
    username: 'juankmultivoces',
    image:
      'https://firebasestorage.googleapis.com/v0/b/misfans-5dfa8.appspot.com/o/profiles%2FCVZOcMJ9NdmfE4DI3pWX%2Fprofile_image?alt=media&token=0cf27e54-65f6-4d3f-bb55-0d4dc8ceea90'
  },
  {
    username: 'fueteteh',
    image:
      'https://firebasestorage.googleapis.com/v0/b/misfans-5dfa8.appspot.com/o/profiles%2FiRWMTF8Di2MvKVhjJxWI%2Fprofile_image?alt=media&token=4d17d934-02fa-474e-b31f-94aaf0ed515e'
  },
  {
    username: 'soyelnot',
    image:
      'https://firebasestorage.googleapis.com/v0/b/misfans-5dfa8.appspot.com/o/profiles%2FmGeOwV9GxhSGO4SMJn3K%2Fprofile_image?alt=media&token=87fe261b-81e1-4e16-b0f7-1a4609225950'
  },
  {
    username: 'elhueycoyote',
    image:
      'https://ik.imagekit.io/misfans/cfffe8d6c3c54222328d4aabb0dc34df_c5_720x720_QObD3udbM.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1658782579685'
  },
  {
    username: 'juancelis',
    image:
      'https://d1fdloi71mui9q.cloudfront.net/TsQ4ObI0QUSgeDUC30An_PvR2R6CV2N1ZmvbQ'
  }
];

const profilesCardsBottom = [
  {
    username: 'teddykelly',
    image:
      'https://ik.imagekit.io/txlzhrdtltb/83f72fc0-3fba-4b41-ba01-2a475c487cc0_duXPXf3br.jpg'
  },
  {
    username: 'dimehectorg',
    image:
      'https://ik.imagekit.io/txlzhrdtltb/95b3c2b6-efca-4b09-bdb1-fb9092a1ab3f_Bd5EiQDL_.jpg'
  },
  {
    username: 'sulycastillo',
    image:
      'https://firebasestorage.googleapis.com/v0/b/misfans-5dfa8.appspot.com/o/profiles%2FgaEVoLViMPjeSN4mx7kT%2Fprofile_image?alt=media&token=83e79376-9342-4ae8-9ef5-cfd11fd09588'
  },
  {
    username: 'aldomirandar',
    image:
      'https://ik.imagekit.io/txlzhrdtltb/2155d8d3-5c2e-45e2-9d4b-ef62802658aa_kr_YL73oo.jpg'
  },
  {
    username: 'ashleymgn',
    image:
      'https://ik.imagekit.io/txlzhrdtltb/8946e4d3-95f8-4c8d-b0f5-b45c2fd42dcb_a7nqxjJj0.jpg'
  },
  {
    username: 'doctormickas',
    image:
      'https://firebasestorage.googleapis.com/v0/b/misfans-5dfa8.appspot.com/o/profiles%2FSJJhXsgesFNm2jLcDLG4%2Fprofile_image?alt=media&token=2aeaa539-eee7-4782-8bba-3fdb367da1cb'
  },
  {
    username: 'lispastel',
    image:
      'https://firebasestorage.googleapis.com/v0/b/misfans-5dfa8.appspot.com/o/profiles%2Fst0Y7coe3ILKjC3JRlu0%2Fprofile_image?alt=media&token=581a4fab-759b-41e8-84da-4d11a30c18a5'
  },
  {
    username: 'mr.0',
    image:
      'https://ik.imagekit.io/txlzhrdtltb/1f7ac44a-e457-4ede-825a-0421e374fa0a_tBvmWYYkN.jpg'
  },
  {
    username: 'fundadores',
    image:
      'https://ik.imagekit.io/txlzhrdtltb/5f0a03ae-9bba-4492-b1f3-a0b5344d49e5_9yOyI4zwp.jpg'
  },
  {
    username: 'alex',
    image:
      'https://ik.imagekit.io/txlzhrdtltb/be3a3b6c-8ee2-4501-b7c7-49f76334e526_MM2QX615g.jpg'
  },
  {
    username: 'hiram',
    image:
      'https://ik.imagekit.io/txlzhrdtltb/94dc8ba6-1076-485e-af43-8a9a5f51554b_9c453bp2t.jpg'
  },
  {
    username: 'dfect',
    image:
      'https://ik.imagekit.io/misfans/500_Batch_10_343_copy_EAh0Z7jPz.png?ik-sdk-version=javascript-1.4.3&updatedAt=1658867396673'
  },
  {
    username: 'lolitaayala',
    image:
      'https://ik.imagekit.io/misfans/5XAF7DWK6VBRJCFZAMEQCRC4XI_csqlhvqC3.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1658867525086'
  },
  {
    username: 'rrrrube',
    image:
      'https://ik.imagekit.io/misfans/rube_38aK5G1-G.png?ik-sdk-version=javascript-1.4.3&updatedAt=1658867571113'
  }
];

// Opciones de la navbar
const navbarOptions = [
  {
    name: 'Herramientas'
  },
  {
    name: 'Pagos'
  }
];

// Texto del hero section
const HeroText = {
  title: 'La única plataforma que necesitas para monetizar'
};

// Primera seccion de highlights
const FirstHighlights = [
  {
    title: 'Construye tu sitio',
    subtitle: 'Personalízalo, añade tus enlaces y crea productos.',
    icon: MarketFa
  },
  {
    title: 'Compartelo con tu audiencia',
    subtitle:
      'Añade tu enlace en tus redes sociales y compartelo con tu audiencia.',
    icon: ArrowUpRightFa
  },
  {
    title: 'Comienza a monetizar',
    subtitle:
      'Vende tus productos y monetiza de tu audiencia inmediatamente.',
    icon: ChartFa
  }
];

// Primera HeaderSection {Herramientas}
const FirstHeaderSection = {
  title: 'Todas las herramientas que necesitas para monetizar',
  subtitle: 'Hechas por creadores, para creadores.',
  buttonTitle: 'Herramientas',
  buttonIcon: ToolboxFa
};

// Contenido de la galeria/carrusel de herramientas
const HerramientasGalleryContent = [
  {
    title: 'Link-in-bio',
    subtitle: 'Todos tus enlaces y productos, en un solo lugar.',
    iconBox: LinkinbioFa
  },
  {
    title: 'Suscripciones',
    subtitle: 'Crea contenido bajo suscripción para tu audiencia.',
    iconBox: LockFa
  },
  {
    title: 'Donaciones',
    subtitle: 'Recibe donaciones con mensajes especiales.',
    iconBox: HeartboxFa
  },
  {
    title: 'Saludos',
    subtitle: 'Vende saludos personalizados para cualquier ocasión.',
    iconBox: HandFa
  },
  {
    title: 'Preguntas',
    subtitle: 'Responde a las preguntas de tu audiencia.',
    iconBox: QuestionBlockFa
  },
  {
    title: 'Audios',
    subtitle: 'Vende audios personalizados.',
    iconBox: AudiosFa
  },
  {
    title: 'Productos Digitales',
    subtitle: 'Vende cualquier archivo digital o acceso a un enlace.',
    iconBox: DownloadFa
  },
  {
    title: 'NFTs',
    subtitle: 'Comparte tus NFTs con tu audiencia.',
    iconBox: LinkinbioFa
  }
];

// Contenido de las integraciones
const IntegrationsList = [
  {
    title: 'Stripe',
    icon: './img/icons/integrationIcons/Stripe.png'
  },
  {
    title: 'Paypal',
    icon: './img/icons/integrationIcons/Paypal.png'
  },
  {
    title: 'Phantom',
    icon: './img/icons/integrationIcons/Phantom.png'
  },
  {
    title: 'Metamask',
    icon: './img/icons/integrationIcons/Metamask.png'
  },
  {
    title: 'Twitter',
    icon: './img/icons/integrationIcons/Twitter.png'
  },
  {
    title: 'Facebook',
    icon: './img/icons/integrationIcons/Facebook.png'
  },
  {
    title: 'YouTube',
    icon: './img/icons/integrationIcons/YouTube.png'
  },
  {
    title: 'Spotify',
    icon: './img/icons/integrationIcons/Spotify.png'
  },
  {
    title: 'Shopify',
    icon: './img/icons/integrationIcons/Shopify.png'
  },
  {
    title: 'Gloset',
    icon: './img/icons/integrationIcons/Gloset.png'
  }
];

// Segunda HeaderSection {MediaKit}
const SecondHeaderSection = {
  title: 'Consigue esa colaboración que siempre has querido',
  subtitle: 'Natoque sollicitudin nec sed ipsum molestie eros.',
  buttonTitle: 'MediaKit',
  buttonIcon: MediaKitFa
};

// Segunda seccion de highlights
const SecondHighlights = [
  {
    title: 'Siempre actualizado',
    subtitle:
      'Comprende mejor a tu audiencia a través de analíticas detalladas',
    icon: ChartFa
  },
  {
    title: 'Compartelo y mantén el control',
    subtitle:
      'Comprende mejor a tu audiencia a través de analíticas detalladas',
    icon: ChartFa
  },
  {
    title: 'Consigue nuevas colaboraciones',
    subtitle:
      'Comprende mejor a tu audiencia a través de analíticas detalladas',
    icon: ChartFa
  }
];

// Imagen de MediaKit
const MediaKitImage = './img/Demo.png';

// Marcas de MediaKit
const MediaKitBrands = [
  {
    title: 'Rappi',
    icon: './img/icons/brands/Rappi.svg'
  },
  {
    title: 'xiaomi',
    icon: './img/icons/brands/Xiaomi.svg'
  },
  {
    title: 'Beat',
    icon: './img/icons/brands/Beat.svg'
  },
  {
    title: 'wirebit',
    icon: './img/icons/brands/Wirebit.svg'
  },
  {
    title: 'Scape',
    icon: './img/icons/brands/Scape.svg'
  },
  {
    title: 'Aeromexico',
    icon: './img/icons/brands/Aeromexico.svg'
  }
];

// Tercer HeaderSection {Pagos}
const ThirdHeaderSection = {
  title: 'Tu dinero en tu cuenta rápido y seguro',
  subtitle: 'Recibe tus ingresos en tu cuenta bancaria rápido y seguro',
  buttonTitle: 'Pagos',
  buttonIcon: PagosFa
};

// Iconos de MoneyFlow
const MoneyFlowIcons = {
  first: BillFa,
  second: ExchangeFa,
  third: BankFa
};

// Iconos de metodos de pago
const PaymentMethodsIcons = [
  {
    title: 'Paypal',
    icon: '/img/icons/PayPalSecurePayments.svg'
  },
  {
    title: 'Stripe',
    icon: '/img/icons/StripeSecurePayments.svg'
  },
  {
    title: 'Visa',
    icon: '/img/icons/Visa.svg'
  },
  {
    title: 'Mastercard',
    icon: '/img/icons/Mastercard.svg'
  },
  {
    title: 'Amex',
    icon: '/img/icons/Amex.svg'
  },
  {
    title: 'Google Pay',
    icon: '/img/icons/GooglePay.svg'
  },
  {
    title: 'Apple Pay',
    icon: '/img/icons/ApplePay.svg'
  },
  {
    title: 'OXXO',
    icon: '/img/icons/oxxo.svg'
  },
  {
    title: 'SPEI',
    icon: '/img/icons/Spei.svg'
  },
  {
    title: 'Solana Pay',
    icon: '/img/icons/Solana.svg'
  }
];

function Landing() {
  var Element = Scroll.Element;

  // Logo de misfans
  const NavbarLeftIcon = () => {
    return (
      <img
        className="h-[25.65px] w-[120px]"
        src="./misfans-purple.svg"
        alt="Workflow"
      />
    );
  };

  // Boton de inicio de sesion
  const NavbarLoginButton = () => {
    return (
      <BasicButtonLanding
        title="Iniciar sesión"
        onClick={() => {
          window.open('/login', '_self');
        }}
        type="button"
        cn="min-w-[8.5rem] py-2 px-4 rounded-3xl hover:bg-[#e5e7eb]	bg-[#F2F2F2] py-[10px] px-[16px] font-semibold text-[#444444]"
      />
    );
  };

  // Boton de aplicar como creador
  const HeroButton = () => {
    return (
      <BasicButtonLanding
        title="Aplica como creador"
        type="button"
        cn="px-[24px] hover:bg-primary-800 transition-all duration-350 text-white bg-primary-700 focus:ring-primary-700 py-[16px] text-[18px] font-semibold rounded-[999px] "
      />
    );
  };

  // Sorteado random de los profiles cards
  const [list, setList] = useState({
    normal: [],
    inverse: []
  });

  useEffect(() => {
    setList((prev) => ({
      ...prev,
      normal: [...profilesCards].sort(() => Math.random() - 0.5),
      inverse: [...profilesCardsBottom].sort(() => Math.random() - 0.5)
    }));
  }, []);

  return (
    <div className=" bg-[#FEFEFE] bg-landing">
      <MetaDefault />
      <LandingNavbar
        options={navbarOptions}
        rightButton={<NavbarLoginButton />}
        leftIcon={<NavbarLeftIcon />}
      />

      <div className="">
        <LandingHero textos={HeroText} botonInferior={<HeroButton />} />
      </div>
      <div className="hidden sm:grid">
        <InfiniteCarousel profiles={list.normal} />
        <InverseInfiniteCarousel profiles={list.inverse} />
      </div>
      <div className="grid sm:hidden">
        <InfiniteCarouselMobile profiles={list.normal} />
        <InfiniteCarouselInverseMobile profiles={list.inverse} />
      </div>
      <div className="px-[16px] sm:px-[0px]">
        <Highlights highlights={FirstHighlights} />
        <CTA />
        <Element name="Herramientas">
          <div className="h-8"></div>
        </Element>
        <SectionHeader content={FirstHeaderSection} />
      </div>
      <HerramientasGallery contents={HerramientasGalleryContent} />
      <div className="px-[16px] sm:px-[0px]">
        <Integrations contents={IntegrationsList} />
        {/* <Element name="MediaKit">
          <div className="h-8"></div>
        </Element>
        <SectionHeader content={SecondHeaderSection} />
        <Highlights highlights={SecondHighlights} />
        <ImageSection image={MediaKitImage} />
        <Brands contents={MediaKitBrands} /> */}
        <Element name="Pagos">
          <div className="h-8"></div>
        </Element>
        <SectionHeader content={ThirdHeaderSection} />
        <MoneyFlow content={MoneyFlowIcons} />
        <PaymentMethods contents={PaymentMethodsIcons} />
      </div>
      <Footer />
    </div>
  );
}

export default Landing;
