type IconProps = {
  className?: string;
};

const CopyRegularIcon: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      width="33"
      height="36"
      viewBox="0 0 33 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16.0774"
        cy="6.13841"
        r="5.42308"
        fill="#8952FF"
        stroke="#542AE2"
      />
      <rect
        x="0.577148"
        y="15.1"
        width="31"
        height="5"
        rx="2.5"
        fill="#8952FF"
        stroke="#542AE2"
      />
      <rect
        x="0.577148"
        y="22.7923"
        width="31"
        height="5"
        rx="2.5"
        fill="#8952FF"
        stroke="#542AE2"
      />
      <rect
        x="0.577148"
        y="30.4846"
        width="31"
        height="5"
        rx="2.5"
        fill="#8952FF"
        stroke="#542AE2"
      />
    </svg>
  );
};

export default CopyRegularIcon;
