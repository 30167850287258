import BasicButton from 'components/tailwind/BasicButton';

function CTA() {
  return (
    <div className="mt-[80px] flex justify-center ">
      <div className="flex sm:w-[80vw]  flex-col items-center justify-center gap-[15px] rounded-xl bg-primary pt-2 pb-[40px] text-center sm:gap-0">
        <span className="mt-[40px] text-[40px] font-semibold leading-9 text-white  sm:leading-normal">
          Únete a <span className="font-extrabold">+500</span> creadores
        </span>
        {/* <span className="text-[16px] px-1 font-medium leading-5  text-white sm:leading-normal">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lectus.
        </span> */}
        <BasicButton
          title="Aplica como creador"
          type="button"
          onClick={() => {
            window.open(
              'https://sd8yx7081i5.typeform.com/to/eeglTIla',
              '_blank'
            );
          }}
          cn="duration-350  mt-[32px] block w-[208px] rounded-[999px] border border-transparent bg-[#663fe5] px-[24px] text-center leading-7  text-base text-[16px] font-semibold text-white  transition-all hover:bg-primary-800 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 "
        ></BasicButton>
      </div>
    </div>
  );
}

export default CTA;
