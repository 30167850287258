import HerramientasBox from './herramientasBox';
import { SVGProps } from 'react';
import InfiniteCarousel from './infiniteCarouselProfiles/infiniteCarousel';
import InfiniteCarouselTools from './InifnityCarrouselTools/InfiniteCarrouselTools';
interface iHerramientasGalleryProps {
  contents: {
    title: string;
    subtitle: string;
    iconBox: any;
  }[];
}

function HerramientasGallery({ contents }: iHerramientasGalleryProps) {
  return (
    <div>
      <div className="mt-[60px] hidden items-center justify-center md:flex">
        <div className="grid w-[95vw]  grid-cols-2 gap-4 md:grid-cols-3 lg:w-[80vw] lg:grid-cols-4 2xl:w-[60vw]">
          {contents.map((item, index) => {
            return <HerramientasBox content={item} />;
          })}
        </div>
      </div>
      <div className="flex sm:hidden">
        <InfiniteCarouselTools profiles={contents} />
      </div>
    </div>
  );
}

export default HerramientasGallery;
