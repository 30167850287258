import { SVGProps } from 'react';

interface iHighlightsProps {
  highlights: {
    title: string;
    subtitle: string;
    icon: any;
  }[];
}

function Highlights({ highlights }: iHighlightsProps) {
  return (
    <div className="mt-[50px] sm:mt-[80px] grid justify-center gap-[32px] sm:flex">
      {highlights.map((highlight, index) => {
        return (
          <div className="flex min-h-[81px] flex-col items-center text-center">
            <div className="flex gap-[12px]">
              <highlight.icon className="w-5 text-[#542AE2]" />
              <span className="truncate font-semibold text-[#111111] lg:text-[20px] ">
                {highlight.title}
              </span>
            </div>
            <span className="max-w-[330px] text-[16px] font-medium text-[#4E4E4E]">
              {highlight.subtitle}
            </span>
          </div>
        );
      })}
    </div>
  );
}

export default Highlights;
