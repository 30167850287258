//Utils
import { Mixpanel } from 'utils/mixpanel';

interface iLandingHeroProps {
  textos: { title: string; subtitle?: string };
  botonInferior: React.ReactNode;
}

function LandingHero({ textos, botonInferior }: iLandingHeroProps) {
  return (
    <div className="mt-[80px] flex flex-col items-center justify-center">
      <span className="mb-[16px] w-[90vw] text-center  text-[48px] font-bold leading-none sm:text-[80px] lg:w-[65vw] 2xl:w-[60vw] ">
        {textos.title}
      </span>
      <span className="mx-[10px] mb-[32px] text-center">{textos.subtitle}</span>
      <a
        target="_blank"
        href="https://sd8yx7081i5.typeform.com/to/eeglTIla"
        className="inline-flex space-x-4"
        onClick={() => {
          Mixpanel.track('Click on apply as creator');
        }}
      >
        <div className="">{botonInferior}</div>
      </a>
    </div>
  );
}

export default LandingHero;
