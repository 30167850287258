import { SVGProps } from 'react';

interface iHerramientasBoxProps {
  content: {
    title: string;
    subtitle: string;
    iconBox: any;
  };
}

function HerramientasBox({ content }: iHerramientasBoxProps) {
  return (
    <div className="flex flex-col  h-[230px] rounded-[12px]  border-[1px] border-[#F3F3F3] bg-white px-[16px] py-[24px] shadow  ">
      <div className="flex h-[60px] w-[60px] items-center justify-center rounded-[12px] bg-[#E0D8FF]">
        <content.iconBox className="w-[32px] stroke-[#542AE2]   stroke-[0.4px] text-[#8952FF]" />
      </div>
      <span className="mt-[16px] text-[18px] font-semibold text-[#111111] ">
          {content.title}
      </span>
      <span className="mt-[8px] text-[16px] font-medium text-[#4E4E4E] ">
          {content.subtitle}
      </span>
    </div>
  );
}

export default HerramientasBox;
