import { useScrollPosition } from 'hooks/useScrollPosition';
import Scroll from 'react-scroll';

interface iNavbarProps {
  options: { name: string }[];
  rightButton: React.ReactNode;
  leftIcon?: React.ReactNode;
}

function LandingNavbar({ options, rightButton, leftIcon }: iNavbarProps) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  var Link = Scroll.Link;
  const scrollPosition = useScrollPosition();

  return (
    <div
      className={classNames(
        scrollPosition > 0 &&
          'border-[1px] border-b-[#E3E3E3] bg-[#FEFEFE] shadow-sm',
        'sticky top-[20px] left-0 right-0 z-10 mx-2 sm:m-auto mb-[30px]  rounded-[999px] transition-all duration-300 sm:top-[30px] lg:max-w-[60vw]'
      )}
    >
      <div className=" mx-[32px] flex h-[76px]  items-center  justify-between ">
        {leftIcon}
        <div className="hidden   justify-center gap-[40px] lg:flex ">
          {options.map((option) => (
            <div className="">
              <Link to={option.name} smooth={true} duration={350}>
                <span className="cursor-pointer text-base font-medium leading-6  text-gray-500 transition-all duration-300 hover:text-gray-700">
                  {option.name}
                </span>
              </Link>
            </div>
          ))}
        </div>
        <form action="/login">{rightButton}</form>
      </div>
    </div>
  );
}

export default LandingNavbar;
