interface iMoneyFlowProps {
  content: {
    first: any;
    second: any;
    third: any;
  };
}
function MoneyFlow({ content }: iMoneyFlowProps) {
  const iconBoxStyle =
    'flex h-[100px] w-[100px] items-center justify-center rounded-[12px] bg-[#F2F2F2] rotate-[270deg] md:rotate-0';
  const iconStyle = 'h-[40px] w-[30px] text-[#999999]';
  const lineStyle = 'border-t-4 border-dashed grow h-[50%]';
  return (
    <div className="my-[160px] flex items-center justify-center md:my-[0px] md:mt-[60px] ">
      <div className="flex w-[90vw] rotate-90 items-center justify-center md:rotate-0 lg:w-[70vw] 2xl:w-[45vw]">
        <div className={iconBoxStyle}>
          <content.first className={iconStyle} />
        </div>
        <div className={lineStyle}></div>
        <div className={iconBoxStyle}>
          <content.second className={iconStyle} />
        </div>
        <div className={lineStyle}></div>
        <div className={iconBoxStyle}>
          <content.third className={iconStyle} />
        </div>
      </div>
    </div>
  );
}

export default MoneyFlow;
