interface iIntegrationsProps {
  contents: {
    title: string;
    icon?: string;
  }[];
}

function Integrations({ contents }: iIntegrationsProps) {
  return (
    <div className="grid mt-[60px]">
      <span className="mb-[30px] text-[#666666] font-medium text-[18px] text-center">+ 10 Integraciones diferentes</span>
      <div className="grid grid-cols-5 px-[5px]  sm:flex items-center justify-center gap-[16px] max-w-[100%] ">
        {contents.map((content, index) => (
          <img src={content.icon} className="sm:h-[60px] sm:w-[60px]" />
        ))}
      </div>
    </div>
  );
}

export default Integrations;
