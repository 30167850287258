import React from 'react';
import classNames from 'utils/class-names';

type BasicButtonProps = {
  type: 'button' | 'submit' | 'reset';
  title: string;
  onClick?: () => void;
  cn?: string;
  disabled?: boolean;
};

const BasicButtonLanding = ({
  type,
  title,
  onClick,
  disabled,
  cn = ''
}: BasicButtonProps) => {
  return (
    <button
      type={type}
      className={classNames(
        cn,
        disabled
          ? 'cursor-not-allowed opacity-50'
          : 'cursor-pointer ',
        'flex w-full justify-center rounded-md border border-transparent shadow-sm focus:outline-none focus:ring-2  focus:ring-offset-2'
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

export default BasicButtonLanding;
