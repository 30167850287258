interface iPaymentMethodsProps {
  contents: {
    title: string;
    icon: string;
  }[];
}

function PaymentMethods(contents: iPaymentMethodsProps) {
  return (
    <div className=" mb-[70px] grid md:mt-[60px] md:mb-[158px]">
      <span className="mb-[30px] text-center  mt-[30px]  text-[12px] font-medium text-[#7D7D7D] sm:text-[18px]">
        Acepta multiples métodos de pago en tu sitio
      </span>
      <div className="grid grid-cols-5 place-items-center  items-center justify-center gap-[10px] md:flex ">
        {contents.contents.map((content, index) => (
          <img src={content.icon} className="h-[40px] w-[79px]" />
        ))}
      </div>
    </div>
  );
}

export default PaymentMethods;
