import React, { useState } from 'react';
import styles from './infiniteCarousel.module.css';

interface InfiniteCarouselProps {
  profiles: { username: string; image: string }[];
}

function InfiniteCarouselMobile({ profiles }: InfiniteCarouselProps) {
  return (
    <section className={styles.section}>
      <article className={styles.article}>
        <div className={styles.div}>
          <ul className={styles.ul}>
            {profiles.map((profile, index) => (
              <a className={styles.li} key={index} href={`/${profile.username}`}>
                <img
                  data-img={profile.image}
                  className={styles.img}
                  src={profile.image}
                  alt={profile.username}
                  placeholder="blur"
                />
                <span className="flex justify-center text-[16px] font-semibold">
                  {profile.username}
                </span>
              </a>
            ))}
          </ul>
        </div>
      </article>
    </section>
  );
}

export default InfiniteCarouselMobile;
