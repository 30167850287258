import React from 'react';
import styles from './infiniteCarrouselTools.module.css';
import { SVGProps } from 'react';
import HerramientasBox from '../herramientasBox';

interface InfiniteCarouselProps {
  profiles: {
    title: string;
    subtitle: string;
    iconBox: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  }[];
}

function InfiniteCarouselTools({ profiles }: InfiniteCarouselProps) {
  return (
    <section className={styles.section}>
      <article className={styles.article}>
        <div className={styles.div}>
          <ul className={styles.ul}>
            {profiles.map((profile, index) => (
              <a className={styles.li} key={index}>
                <HerramientasBox content={profile} />
              </a>
            ))}
          </ul>
        </div>
      </article>
    </section>
  );
}

export default InfiniteCarouselTools;
